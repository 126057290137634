import React, {useEffect, useState} from 'react';
import {useLocation} from 'react-router-dom';
import { Hidden, Box } from '@mui/material';
import IconButton from '@mui/material/IconButton';
import {styled, useTheme} from '@mui/material/styles';
import InfoOutlinedIcon from '@mui/icons-material/InfoOutlined';
import HelpSystemContext from '../context/HelpSystemContext';
import HelpSystemDrawer from './HelpSystemDrawer';

const drawerWidth = 360;

const Main = styled('main', {shouldForwardProp: (prop) => prop !== 'open'})(
    ({theme, open}) => ({
        flexGrow: 1,
        transition: theme.transitions.create('margin', {
            easing: theme.transitions.easing.sharp,
            duration: theme.transitions.duration.leavingScreen,
        }),
        marginRight: `-${drawerWidth}px`,
        ...(open && {
            transition: theme.transitions.create('margin', {
                easing: theme.transitions.easing.easeOut,
                duration: theme.transitions.duration.enteringScreen,
            }),
            marginRight: 0,
        }),
    }),
);

// noinspection FunctionNamingConventionJS
function HelpSystemComponent(props) {

    const theme = useTheme();
    const location = useLocation();

    const [open, setOpen] = useState(false);
    const [post, setPost] = useState('');
    const [fileName, setFileName] = useState('');
    const [provider, setProvider] = useState('');

    const handleDrawerOpen = () => {
        setOpen(true);
    };

    const handleDrawerClose = () => {
        setOpen(false);
    };

    useEffect(() => {
        setFileName(location.pathname
            .replace(/^\/|\/$/g, '')
            .split('/')
            .join('_')
            .replace(/_[0-9]+/g, '') + '.md');
    }, [location.pathname]);

    useEffect(() => {
        const searchParams = new URLSearchParams(location.search);
        if (searchParams.has('provider')) {
            setProvider(`/${searchParams.get('provider')}`);
        } else {
            setProvider('');
        }
    }, [location.pathname, location.search]);

    useEffect(() => {
        if (open) {
            import(`../helpDoc${provider}/${fileName}`)
                .then(res => {
                    fetch(res.default)
                        .then(res => res.text())
                        .then(text => setPost(text.toString()))
                        .catch(err => console.error(err));
                })
                .catch(err => console.error(err));
        }
    }, [open, fileName, provider]);

    return <>
        <Hidden mdDown>
            <Box sx={{display: 'flex'}}>

                <HelpSystemContext.Provider value={open}>
                    <Main open={open} className={open ? 'help-opened' : ''}>
                        {props.children}
                    </Main>
                </HelpSystemContext.Provider>

                <div style={{...(open && {paddingRight: theme.spacing(3)}), ...(!open && {pointerEvents: 'none'})}}>
                    <Box sx={{display: 'flex', justifyContent: 'flex-end', paddingTop: '2px', paddingRight: '8px'}}>
                        <IconButton style={{pointerEvents: 'auto'}} onClick={handleDrawerOpen} size='small'
                                    sx={{mr: 2, ...(open && {display: 'none'})}}>
                            <InfoOutlinedIcon/>
                        </IconButton>
                    </Box>

                    <HelpSystemDrawer drawerAnchor='right' post={post}
                                      handleDrawerClose={handleDrawerClose} open={open} drawerWidth={drawerWidth} />
                </div>
            </Box>
        </Hidden>


        <Hidden mdUp>

            {!open &&
            <HelpSystemContext.Provider value={open}>
                {props.children}
            </HelpSystemContext.Provider>
            }

            <Box sx={{display: 'flex', justifyContent: 'flex-end', position: 'absolute', top: '64px', right: '12px'}}>
                <IconButton onClick={handleDrawerOpen} size='small'
                            sx={{mr: 2, ...(open && {display: 'none'})}}>
                    <InfoOutlinedIcon/>
                </IconButton>
            </Box>

            <HelpSystemDrawer drawerAnchor='top' post={post}
                              handleDrawerClose={handleDrawerClose} open={open} drawerWidth={'100%'} />
        </Hidden>
    </>;
}

HelpSystemComponent.propTypes = {};

HelpSystemComponent.defaultProps = {};

export default HelpSystemComponent;
