import React, { useState } from 'react';
import axios from 'axios';
import styled from 'styled-components';
import { Publish } from '@mui/icons-material';
import { Button, FormHelperText, Grid, Input, InputAdornment, Menu, MenuItem, Typography } from '@mui/material';
import api_routes from '../../../util/api_routes';
import httpStatus from '../../../util/http_status';
import PageSection from '../../common/PageSection';
import CircularProgressButton from '../../common/CircularProgressButton';
import ArrowDropDownIcon from "@mui/icons-material/ArrowDropDown";
import { useTheme } from "@mui/material/styles";

const StyledMessageItem = styled(Typography)`
    margin-left: 1.25rem;
`;

const StyledHelperText = styled(FormHelperText)`
`;
const StyledInput = styled(Input)`
  background-color: ${props => {
  let color;
  if (props.importcomplete === 'true') {
    color = `rgba(0, 255, 0, 0.15)`;
  } else {
    color = 'rgba(0,0,0,0.02)';
  }
  return color;
}};
  color: ${props => {
  let color;
  if (props.importcomplete === ' true') {
    color = `rgb(11, 121, 11)`;
  } else {
    color = '#333';
  }
  return color;
}};
  width: 13rem;
  font-size: 14px;
  font-family: "Open Sans", sans-serif;
  padding-left: 12px;
  border-radius: 5px;
  padding-right: 12px;
  &:hover{
    cursor: pointer;
 }
  input[type=file]::-webkit-file-upload-button {
    display: none;
  }
`;

const exportOptions = ['Export Backup File', 'Export Support Backup File']

const StyledExportButton = styled(Button)`
  text-transform: none;
`;

function SettingsBackupComponent(props) {
  const theme = useTheme();

  const [loadingImportArtifact, setLoadingImportArtifact] = useState(false);
  const [anchorEl, setAnchorEl] = useState(null);
  const [importStatus, setImportStatus] = useState({
    complete: false,
    message: []
  });
  const [selectedFile, setSelectedFile] = useState({
    file: null,
    name: ''
  });

  const downloadBackupArtifact = (content) => {
    const url = window.URL.createObjectURL(
        new Blob([content.data], {type: content.headers['content-type']}))
    const link = document.createElement('a');
    link.href = url;
    const suggestedFileName = content.headers["content-disposition"].split("filename=")[1];
    const effectiveFileName = (suggestedFileName === undefined
        ? "SFTPGatewayBackup.yaml"
        : suggestedFileName.replaceAll('"', ''));
    link.setAttribute('download', effectiveFileName);
    link.click();
    setTimeout(() => window.URL.revokeObjectURL(url), 0); // this is important too, otherwise we will be unnecessarily spiking memory!
  };

  const handleExportArtifact = async (event, index) => {
    const params = (index === 1 ? {support: true} : null);
    try {
      axios.get(
          `${api_routes.backup.endpoint}`, {
            params: {...params},
            responseType: 'blob'
          },
      ).then(
          response => {
            downloadBackupArtifact(response);
          }
      );
    } catch (error) {
      alert("Export failed due to: " + error);
      console.error(error);
    } finally {
      setAnchorEl(null);
    }
  };

  const handleImportArtifact = () => {
    let reader = new FileReader();
    reader.onload = async function readOnLoad(e) {
      setLoadingImportArtifact(true);
      let contents = e.target.result;
      try {
        const response = await axios.post(`${api_routes.backup.endpoint}`,
          Buffer.from(contents).toString("utf-8"),
          {
            headers:
              { 'content-type': 'text/json' }
          }
          )
        if (response.status === httpStatus.ok) {
          setImportStatus({
            complete: true,
            message: response.data
          });

        }
      } catch (error) {
        alert("Import failed due to: " + error);
        console.error(error);
      } finally {
        setLoadingImportArtifact(false);
        props.refresh()
      }

    };
    reader.readAsBinaryString(selectedFile.file);
  };

  const handleSelectFile = (event) => {
    let inputFile = event.target.files[0];
    setImportStatus({
      complete: false,
      message: []
    });
    setSelectedFile({
      file: inputFile,
      name: inputFile?.name
    });
  };

  return (<>
    <PageSection title='Backup and Recovery'
                 subtitle='The following can be used to export and import backup and recovery artifacts.'
    />

    <Grid container direction={"column"} alignItems={"flex-start"} spacing={1}>
      <Grid item>
        <StyledExportButton theme={theme} size='small' color='primary' variant='contained'
                            disableElevation
                            aria-controls='user-menu'
                            aria-haspopup='true'
                            onClick={(event) => setAnchorEl(event.currentTarget)}
                            endIcon={<ArrowDropDownIcon/>}>
          Export
        </StyledExportButton>
        <Menu id='user-menu'
              keepMounted
              anchorEl={anchorEl}
              open={Boolean(anchorEl)}
              onClose={() => setAnchorEl(null)}
              getContentAnchorEl={null}
              anchorOrigin={{
                vertical: 'bottom',
                horizontal: 'left'
              }}
              transformOrigin={{
                vertical: 'top',
                horizontal: 'left'
              }}
        >
          {exportOptions.map((option, index) => (
              <MenuItem
                  theme={theme}
                  key={option}
                  onClick={(event) => handleExportArtifact(event, index)}
              >
                {option}
              </MenuItem>
          ))}
        </Menu>
      </Grid>
      <Grid item>
        <Grid container item style={{marginTop: '1rem'}}>
          <StyledInput type='file' importcomplete={importStatus.complete.toString()} name='backupupload'
                       inputProps={{accept: '.yaml,.yml'}}
                       onChange={handleSelectFile}
                       startAdornment={
                         <InputAdornment position="start">
                           <Publish/>
                         </InputAdornment>
                       }
          />
          <CircularProgressButton buttonTextTransform='none' mt={0} label='Import'
                                  size='small' inProgress={loadingImportArtifact}
                                  disabled={!selectedFile.file}
                                  onClick={handleImportArtifact}/>
        </Grid>
        {importStatus.complete
            ? <StyledHelperText id='import-helper-text'>Import Complete</StyledHelperText>
            : <FormHelperText> Imported files must have the extension ".yaml" or ".yml" </FormHelperText>
        }
      </Grid>
    </Grid>

    {importStatus.complete &&
        <Grid container direction={"column"}>
          <Typography variant={"h6"}>Import Status Messages:</Typography>
          {importStatus.message.map(
              (str, idx) => (<StyledMessageItem key={idx} variant={"body1"}>{str}</StyledMessageItem>))
          }
        </Grid>
    }
  </>);
}

export default SettingsBackupComponent;
