import {combineReducers} from 'redux';
import {UNKNOWN} from '../util/auth_methods';

const initialState = {
    authenticated: false,
    currentUser: {
        username: 'Anonymous',
    },
    systemInfo: {
        authMethod: UNKNOWN,
    },
    userSort: {
        by: 'username',
        order: 'ASCENDING'
    }
};

const auth = (state = initialState.authenticated, action) => {
    let tempState;
    switch (action.type) {
        case 'SET_AUTHENTICATED':
            tempState = {...state, authenticated: action.authenticated};
            break;
        default:
            tempState = state;
            break;
    }
    return tempState
};

const currentUser = (state = initialState.currentUser, action) => {
    let tempState;
    switch (action.type) {
        case 'SET_CURRENT_USER':
            tempState = {...state, username: action.currentUser.username};
            break;
        default:
            tempState = state;
            break;
    }
    return tempState
};

const systemInfo = function systemInfoReducer(state = initialState.systemInfo, action) {
    let tempState;
    switch (action.type) {
        case 'SET_SYSTEM_INFO':
            tempState = {
                ...state,
                isDefaultCloudConnectionSet: action.info.isDefaultCloudConnectionSet,
                activeVersion: action.info.activeVersion,
                cloudProvider: action.info.cloudProvider,
            };
            break;
        case 'SET_AUTH_METHOD': // Keep track of what method was used to log in. Possible methods are in auth_methods.js.
            tempState = {
                ...state,
                authMethod: action.authMethod
            };
            break;
        default:
            tempState = state;
            break;
    }
    return tempState;
};

const userSort = function userSortReducer(state = initialState.userSort, action) {
    let tempState;
    switch (action.type) {
        case 'SET_USER_SORT':
            tempState = {...state, by: action.userSort.by, order: action.userSort.order};
            break;
        default:
            tempState = state;
            break;
    }
    return tempState;
};

const rootReducer = combineReducers({
    auth,
    currentUser,
    systemInfo,
    userSort,
});

export default rootReducer;
