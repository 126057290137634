import {Redirect} from 'react-router-dom';
import {authenticationService} from './authenticationService';
import routes from '../../util/routes';
import React from 'react';
import useQuery from '../../hook/useQuery';

export default function TokenIngest() {
    let query = useQuery();

    // noinspection JSIgnoredPromiseFromCall
    authenticationService.setToken(query.get("token"));

    // noinspection JSIgnoredPromiseFromCall
    authenticationService.setName(query.get("name"));

    console.log(authenticationService.getToken(), authenticationService.getUsername());

    return <Redirect to={routes.home.path}/>;
}
