import React, {useEffect, useState} from 'react';
import axios from 'axios';
import toast from 'toasted-notes';
import PropTypes from 'prop-types';
import styled from 'styled-components';
import {FontAwesomeIcon} from '@fortawesome/react-fontawesome';
import {faCopy as copyIcon} from '@fortawesome/free-regular-svg-icons';
import {Button, Grid, IconButton, InputAdornment, useTheme} from '@mui/material';
import CustomAlert from '../../common/CustomAlert';
import PageSection from '../../common/PageSection';
import FormControlInput from '../../common/FormControlInput';
import FormControlRadioGroup from '../../common/FormControlRadioGroup';
import CircularProgressButton from '../../common/CircularProgressButton';
import AdvancedSectionExpansion from '../../common/AdvancedSectionExpansion';
import TestConnectionResultTable from './TestConnectionResultTable';
import {isEmpty} from '../../../util/helpers';
import api_routes from '../../../util/api_routes';
import {AZURE} from '../../../util/cloud_providers';
import HelpSystemContext from '../../../context/HelpSystemContext';

const StyledButton = styled(Button)`
  margin-top: ${props => props.theme.spacing(2)};
`;

export function getContainerUrl(accountName, endpoint, containerName) {
    return `https://${accountName}.${endpoint}/${containerName}`;
}

// noinspection FunctionNamingConventionJS
function AzureCloudConnectionForm(props) {

    const theme = useTheme();
    const [loadingSubmit, setLoadingSubmit] = useState(false);
    const [azureStorage, setAzureStorage] = useState({
        accountName: '',
        containerName: '',
        endpoint: 'blob.core.windows.net',
    });
    const [localErrors, setLocalErrors] = useState({
        accountName: '',
        containerName: '',
    });

    useEffect(() => {
        setLoadingSubmit(false);
    }, [props.errors]);

    const handleSubmit = async function handleFolderSubmit(event) {
        setLoadingSubmit(true);
        await props.handleSubmit(event);
        let tempErrors = {};
        if (isEmpty(azureStorage.accountName)) {
            tempErrors.accountName = 'Account name cannot be empty.';
        }
        if (isEmpty(azureStorage.containerName)) {
            tempErrors.containerName = 'Container name cannot be empty.'
        }
        setLocalErrors(tempErrors);
    };

    const copyUrl = (url) => {
        navigator.clipboard.writeText(url).then(() => {
            toast.notify(({onClose}) => <CustomAlert type='success' message='Url copied!' onClose={onClose}/>);
        });
    };

    const updateAzureStorage = (event) => {
        const tempAzureStorage = {...azureStorage, [event.target.name]: event.target.value};
        if (!isEmpty(tempAzureStorage.accountName) && !isEmpty(tempAzureStorage.containerName)
            && !isEmpty(tempAzureStorage.endpoint)) {
            let containerUrl = getContainerUrl(tempAzureStorage.accountName, tempAzureStorage.endpoint, tempAzureStorage.containerName);
            if (containerUrl !== props.basePrefix) {
                props.setBasePrefix(containerUrl);
            }
        }
        setAzureStorage({...azureStorage, [event.target.name]: event.target.value});
    };

    const updateBasePrefix = (event) => {
        const tempBasePrefix = event.target.value;
        props.setBasePrefix(tempBasePrefix);
        if (!isEmpty(tempBasePrefix)) {
            let containerUrl = getContainerUrl(azureStorage.accountName, azureStorage.endpoint, azureStorage.containerName);
            if (containerUrl !== tempBasePrefix) {
                try {
                    const url = new URL(tempBasePrefix);
                    const hostParts = url.host.split('.');
                    setAzureStorage({
                        accountName: hostParts[0],
                        containerName: url.pathname.replace(/^\/+/g, ''),
                        endpoint: hostParts.slice(1, hostParts.length).join('.')
                    });
                } catch (error) {
                    console.error('Entered URL is malformed');
                    setAzureStorage({
                        accountName: '',
                        containerName: '',
                        endpoint: 'blob.core.windows.net'
                    });
                }
            }
        }
    }

    const testConnectivity = async () => {
        return await axios.post(`${api_routes.cloudConnectionTest.endpoint}`, {
            basePrefix: props.basePrefix,
            connectionString: props.connectionString,
            useInstanceCredentials: props.useInstanceCredentials,
            cloudProvider: AZURE,
        });
    }

    //noinspection MagicNumberJS, ConditionalExpressionJS
    return (
        <HelpSystemContext.Consumer>
            {open =>
                <form onSubmit={handleSubmit} noValidate>
                    <Grid container justifyContent='center'>
                        <Grid container item md={open ? 12 : 8}>
                            <FormControlInput value={props.name} width='100' label='Connection Name' name='name'
                                              placeholder='Enter unique name' onChange={props.setName} required
                                              helperText='Name used to identify this cloud connection.'
                                              errorMessage={props.errors.name}
                            />
                        </Grid>
                        <Grid container item md={open ? 12 : 8}>
                            <FormControlInput value={props.notes} width='100' label='Cloud Connection Notes'
                                              name='notes' multiline onChange={props.setNotes}
                                              inputProps={{maxLength: 1000}} showCount
                                              placeholder='Enter notes about the cloud connection (optional)'
                                              errorMessage={props.errors.notes}
                            />
                        </Grid>

                        <Grid container item md={open ? 12 : 8}>
                            <FormControlInput name='accountName'
                                              value={azureStorage.accountName}
                                              width='100'
                                              label='Storage Account Name'
                                              autoComplete='off'
                                              required
                                              onChange={updateAzureStorage}
                                              errorMessage={localErrors.accountName || props.errors.accountName}
                            />
                        </Grid>
                        <Grid container item md={open ? 12 : 8}>
                            <FormControlInput name='containerName'
                                              value={azureStorage.containerName}
                                              width='100'
                                              label='Container Name'
                                              autoComplete='off'
                                              required
                                              onChange={updateAzureStorage}
                                              errorMessage={localErrors.containerName || props.errors.containerName}
                            />
                        </Grid>

                        <Grid container item md={open ? 12 : 8}>
                            <AdvancedSectionExpansion mt={3}>
                                <Grid item>
                                    <FormControlInput value={props.basePrefix} width='100' label='Container Resource URL'
                                                      name='basePrefix'
                                                      required
                                                      autoComplete="off"
                                                      onChange={updateBasePrefix}
                                                      placeholder='Enter URL for Azure Container'
                                                      helperText='Ex: https://storage-account-name.blob.core.windows.net/container-name'
                                                      errorMessage={props.errors.basePrefix}
                                                      endAdornment={<InputAdornment position='end'>
                                                          <IconButton
                                                              onClick={function handleCopyUrl(event) {
                                                                  event.stopPropagation()
                                                                  copyUrl(props.basePrefix)
                                                              }}
                                                              size="large">
                                                              <FontAwesomeIcon icon={copyIcon} color={theme.palette.primary.main}
                                                                               size={"xs"}/>
                                                          </IconButton>
                                                      </InputAdornment>}
                                    />
                                </Grid>
                            </AdvancedSectionExpansion>
                        </Grid>
                        <Grid container item md={open ? 12 : 8}>

                            <PageSection title='Cloud Connection Credentials' mt={4} pb={0} titleVariant='body1'
                                         subtitle='Credentials used to access this cloud connection.' subtitleVariant='body2'/>
                            <FormControlRadioGroup name='useInstanceCredentials' value={props.useInstanceCredentials}
                                                   onChange={props.setInstanceCredentials}
                                                   options={[
                                                       ...(props.showInstanceCredentialsOption ?
                                                           [{value: true, label: 'Use instance identity'}] :
                                                           []),
                                                       {value: false, label: 'Use connection string'}
                                                   ]}
                            />
                        </Grid>
                        <Grid container item md={open ? 12 : 8}>
                            <div style={{width: '100%', paddingLeft: '28px', marginTop: '-16px'}}>
                                <FormControlInput value={props.connectionString} width='100' label='Connection String'
                                                  helpLink={{
                                                      href: 'http://www.sftpgateway.com/azure-connection-string.html',
                                                      target: '_blank',
                                                      text: 'Where do I find the connection string?'
                                                  }}
                                                  autoComplete="off"
                                                  name='connectionString' onChange={props.setConnectionString}
                                                  placeholder={`${props.connectionStringSet ? '**********' : 'Enter connection string'}`}
                                                  helperText='This is the default connection string used for this cloud connection.'
                                                  errorMessage={props.errors.connectionString}
                                                  disabled={props.useInstanceCredentials} required={!props.useInstanceCredentials}
                                />
                            </div>
                        </Grid>
                        {!isEmpty(props.connectivity) &&
                        <Grid container item md={open ? 12 : 8}>
                            <TestConnectionResultTable connectivity={props.connectivity}
                                                       disabled={(!props.useInstanceCredentials && isEmpty(props.connectionString)) ||
                                                       (props.useInstanceCredentials && isEmpty(props.basePrefix))}
                                                       test={testConnectivity}/>
                        </Grid>
                        }
                        <Grid container item justifyContent='flex-end' md={open ? 12 : 8}>
                            <CircularProgressButton theme={theme} type='submit' size='small' mr={1}
                                                    label='Save' mt={2} inProgress={loadingSubmit}/>
                            <StyledButton onClick={props.handleCancel} disableElevation size='small' type='reset'
                                          theme={theme}>Cancel</StyledButton>
                        </Grid>
                    </Grid>
                </form>
            }
        </HelpSystemContext.Consumer>
    );
}

AzureCloudConnectionForm.propTypes = {
    name: PropTypes.string,
    setName: PropTypes.func,
    notes: PropTypes.string,
    setNotes: PropTypes.func,
    basePrefix: PropTypes.string,
    setBasePrefix: PropTypes.func,
    accountName: PropTypes.string,
    setAccountName: PropTypes.func,
    connectionString: PropTypes.string,
    setConnectionString: PropTypes.func,
    region: PropTypes.string,
    setRegion: PropTypes.func,
    useInstanceCredentials: PropTypes.bool,
    setInstanceCredentials: PropTypes.func,
    handleSubmit: PropTypes.func,
    handleCancel: PropTypes.func,
    errors: PropTypes.object,
    accessSecretSet: PropTypes.bool,
    connectivity: PropTypes.object,
    showInstanceCredentialsOption: PropTypes.bool
};

AzureCloudConnectionForm.defaultProps = {
    accessSecretSet: false,
};

export default AzureCloudConnectionForm;
