import React from 'react';
import {BrowserRouter as Router, Redirect, Route, Switch} from 'react-router-dom';
import {Container, CssBaseline} from '@mui/material';
import {StyledEngineProvider, ThemeProvider} from '@mui/material/styles';
import 'toasted-notes/src/styles.css';

import routes from './util/routes';
import sftpgwTheme from './theme/sftpgateway';
import Authenticator from './container/auth/Authenticator';
import UserListContainer from './container/user/UserListContainer';
import UserCreateContainer from './container/user/UserCreateContainer';
import SettingsContainer from './container/settings/SettingsContainer';
import UserUpdateContainer from './container/user/UserUpdateContainer';
import FolderListContainer from './container/folder/FolderListContainer';
import FolderCreateContainer from './container/folder/FolderCreateContainer';
import FolderUpdateContainer from './container/folder/FolderUpdateContainer';
import ChangePasswordContainer from './container/user/ChangePasswordContainer';
import CloudConnectionUpdateContainer from './container/settings/CloudConnectionUpdateContainer';
import CloudConnectionCreateContainer from './container/settings/CloudConnectionCreateContainer';
import IdentityProviderCreateContainer from './container/settings/IdentityProviderCreateContainer';
import IdentityProviderUpdateContainer from './container/settings/IdentityProviderUpdateContainer';
import Navigation from './component/layout/Navigation';
import FooterComponent from './component/FooterComponent';
import SystemInfoComponent from './component/SystemInfoComponent';
import HelpSystemComponent from './component/HelpSystemComponent';
import ConditionalChangePasswordRoute from './component/common/ConditionalChangePasswordRoute';
import TokenIngest from "./container/auth/TokenIngest";

const initFeatures = () => {
    localStorage.setItem('flags', JSON.stringify([
        {name: 'azure', description: 'Displays the Azure UI features', active: true},
        {name: 'gcp', description: 'Displays the Google UI features', active: true},
    ]));
}

// noinspection FunctionNamingConventionJS
function App() {
    initFeatures();

    return (
        <StyledEngineProvider injectFirst>
            <ThemeProvider theme={sftpgwTheme}>
                <CssBaseline/>
                <Router>
                    <Switch>
                        <Route exact path={`${routes.token.path}`}>
                            <TokenIngest/>
                        </Route>
                        <Authenticator>
                            <Navigation/>
                            <Container>
                                <HelpSystemComponent>
                                    <SystemInfoComponent/>
                                    <div className={'content'}>
                                        <Route exact path={routes.users.path}>
                                            <UserListContainer/>
                                        </Route>
                                        <Route exact
                                               path={`${routes.users.path}${routes.createUser.path}`}>
                                            <UserCreateContainer/>
                                        </Route>
                                        <Route exact
                                               path={`${routes.users.path}/:userId${routes.updateUser.path}`}>
                                            <UserUpdateContainer/>
                                        </Route>
                                        <ConditionalChangePasswordRoute exact
                                                                        path={routes.profile.path}
                                                                        component={ChangePasswordContainer}/>
                                        <Route exact path={`${routes.settings.path}`}>
                                            <SettingsContainer/>
                                        </Route>
                                        <Route exact
                                               path={`${routes.settings.path}${routes.cloudConnections.path}${routes.createCloudConnection.path}`}>
                                            <CloudConnectionCreateContainer/>
                                        </Route>
                                        <Route exact
                                               path={`${routes.settings.path}${routes.cloudConnections.path}/:cloudConnectionId${routes.updateCloudConnection.path}`}>
                                            <CloudConnectionUpdateContainer/>
                                        </Route>
                                        <Route exact
                                               path={`${routes.settings.path}${routes.identityProviders.path}${routes.createIdentityProvider.path}`}>
                                            <IdentityProviderCreateContainer/>
                                        </Route>
                                        <Route exact
                                               path={`${routes.settings.path}${routes.identityProviders.path}/:identityProviderId${routes.updateIdentityProvider.path}`}>
                                            <IdentityProviderUpdateContainer/>
                                        </Route>
                                        <Route exact path={routes.folders.path()}>
                                            <FolderListContainer/>
                                        </Route>
                                        <Route exact path={`${routes.folders.path()}${routes.createFolder.path}`}>
                                            <FolderCreateContainer/>
                                        </Route>
                                        <Route exact path={`${routes.folders.path()}${routes.updateFolder.path}`}>
                                            <FolderUpdateContainer/>
                                        </Route>
                                        <Redirect from={'/'} to={routes.users.path}/>

                                    </div>
                                    <div className='footer'>
                                        <FooterComponent/>
                                    </div>
                                </HelpSystemComponent>
                            </Container>
                        </Authenticator>

                    </Switch>
                </Router>
            </ThemeProvider>
        </StyledEngineProvider>
    );
}

export default App;
