import React, {useState} from 'react';
import toast from 'toasted-notes';
import PropTypes from 'prop-types';
import {useHistory} from 'react-router-dom';
import {Card, CardContent, useTheme} from '@mui/material';
import makeStyles from '@mui/styles/makeStyles';

import CustomAlert from '../../common/CustomAlert';
import CustomCardHeader from '../../common/CustomCardHeader';
import {StyledBreadcrumbIcon, StyledBreadcrumbLink, StyledBreadcrumbs} from '../../common/styled';
import IdentityProviderForm from './IdentityProviderForm';
import routes from '../../../util/routes';
import {convertErrorsToObject} from '../../../util/errorHandler';

const useStyles = makeStyles((theme) => ({
    root: {
        padding: theme.spacing(3),
    },
}));

const initIdentityProvider = {
    name: '',
    notes: '',
    oidcConfigEndpoint: '',
    clientId: '',
    clientSecret: '',
};

// noinspection FunctionNamingConventionJS
function IdentityProviderCreateComponent(props) {

    const theme = useTheme();
    const classes = useStyles();
    const history = useHistory();

    const [isBlocking, setIsBlocking] = useState(false);
    const [identityProvider, setIdentityProvider] = useState(initIdentityProvider);
    const [errors, setErrors] = useState(initIdentityProvider);

    const handleTextChange = function handleTextChangeOnCloudConnectionForm(event) {
        setIsBlocking(true);
        setIdentityProvider({...identityProvider, [event.target.name]: event.target.value});
    };

    const onSubmit = async function createIdentityProvider(event) {
        event.preventDefault();
        try {
            await props.saveIdentityProvider(identityProvider);
            toast.notify(({onClose}) =>
                <CustomAlert message={`Created identity provider ${identityProvider.name}.`} onClose={onClose}/>);
            history.push(routes.settings.path);
        } catch (error) {
            let tempErrors = convertErrorsToObject(error.response);
            setErrors(tempErrors);
        }
    }

    const handleGoBack = function handleGoBack() {
        if (isBlocking) {
            if (window.confirm('Are you sure you would like to go settings? Your changes will not be saved.')) {
                setIsBlocking(false);
                history.push(routes.settings.path);
            }
        } else {
            history.push(routes.settings.path);
        }
    }

    const handleCancel = function handleCancel() {
        if (isBlocking) {
            if (window.confirm('Are you sure you would like to cancel? Your changes will not be saved.')) {
                setIsBlocking(false);
                history.push(routes.settings.path);
            }
        } else {
            history.push(routes.settings.path);
        }
    };

    return (
        <div className={classes.root}>
            <StyledBreadcrumbs aria-label='breadcrumb' theme={theme}>
                <StyledBreadcrumbLink color='inherit' onClick={handleGoBack}>
                    <StyledBreadcrumbIcon theme={theme}/>
                    Back to settings
                </StyledBreadcrumbLink>
            </StyledBreadcrumbs>

            <Card variant='outlined'>
                <CustomCardHeader
                    title={routes.createIdentityProvider.pageTitle}
                    titleAlign='center'
                />
                <CardContent>
                    <IdentityProviderForm handleSubmit={onSubmit} handleCancel={handleCancel}
                                          name={identityProvider.name} setName={handleTextChange}
                                          notes={identityProvider.notes} setNotes={handleTextChange}
                                          issuerUri={identityProvider.issuerUri} setIssuerUri={handleTextChange}
                                          clientId={identityProvider.clientId} setClientId={handleTextChange}
                                          clientSecret={identityProvider.clientSecret} setClientSecret={handleTextChange}
                                          registrationId={identityProvider.registrationId} setRegistrationId={handleTextChange}
                                          nameAttribute={identityProvider.nameAttribute} setNameAttribute={handleTextChange}
                                          errors={errors}/>
                </CardContent>
            </Card>
        </div>
    );
}

IdentityProviderCreateComponent.propTypes = {
    saveIdentityProvider: PropTypes.func,
};

IdentityProviderCreateComponent.defaultProps = {};

export default IdentityProviderCreateComponent;
